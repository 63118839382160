var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { injectGlobal } from 'styled-components';
import { Home } from './pages/home';
import normalize from 'styled-normalize';
import { ThemeProvider } from 'styled-components';
import { theme } from './theme';
import { Provider } from 'rebass';
var appElement = document.createElement('div');
appElement.setAttribute('id', 'app');
document.body.appendChild(appElement);
injectGlobal(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n @import url('https://fonts.googleapis.com/css?family=Open+Sans&subset=cyrillic');\n  * {\n\n    box-sizing: border-box; }\n    body { margin: 0;\n  }\n  ", "\n"], ["\n @import url('https://fonts.googleapis.com/css?family=Open+Sans&subset=cyrillic');\n  * {\n\n    box-sizing: border-box; }\n    body { margin: 0;\n  }\n  ", "\n"])), normalize);
ReactDOM.render(React.createElement(BrowserRouter, null,
    React.createElement(Provider, null,
        React.createElement(ThemeProvider, { theme: theme },
            React.createElement(Home, null)))), document.getElementById('app'));
var templateObject_1;

var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import React, { Component } from 'react';
import styled from 'styled-components';
import * as _ from 'lodash';
import { Container, ButtonOutline, Heading, Donut, Lead } from 'rebass';
import { config } from '../config';
var Home = /** @class */ (function (_super) {
    __extends(Home, _super);
    function Home() {
        var _this = _super !== null && _super.apply(this, arguments) || this;
        _this.state = {
            isRunning: false,
            startedDate: 0,
            updatedDate: 0,
            endedDate: 0,
            bytesDownloaded: 0,
            allBytes: 0,
            error: null,
        };
        return _this;
    }
    Home.prototype._downloadRequest = function () {
        var _this = this;
        console.log('_downloadRequest');
        var xhr = new XMLHttpRequest();
        xhr.open('GET', config.API_HOST + "/download", true);
        xhr.onerror = function (error) {
            console.log('onerror');
            _this.setState({
                isRunning: false,
                startedDate: Date.now(),
                updatedDate: Date.now(),
                allBytes: 0,
                error: 'network_error',
            });
        };
        xhr.onloadstart = function () {
            console.log('onloadstart');
            _this.setState({
                isRunning: true,
                startedDate: Date.now(),
                updatedDate: Date.now(),
                bytesDownloaded: 0,
                allBytes: 0,
                error: null,
            });
        };
        xhr.onprogress = function () {
            console.log('onprogress', xhr.response.length);
            _this.setState({
                bytesDownloaded: xhr.response.length,
                updatedDate: Date.now(),
            });
        };
        xhr.onloadend = function (event) {
            var _a;
            console.log('onloadend');
            if (xhr.readyState !== xhr.DONE) {
                return;
            }
            if (xhr.status > 300) {
                _this.setState({
                    updatedDate: Date.now(),
                    endedDate: Date.now(),
                    isRunning: false,
                    error: ((_a = JSON.parse(xhr.response)) === null || _a === void 0 ? void 0 : _a.error) || 'ui_unknown_error',
                });
                return;
            }
            _this.setState({
                updatedDate: Date.now(),
                endedDate: Date.now(),
                isRunning: false,
                error: null,
            });
        };
        xhr.onreadystatechange = function () {
            console.log('onreadystatechange');
            if (xhr.readyState === xhr.HEADERS_RECEIVED) {
                _this.setState({
                    allBytes: parseInt(xhr.getResponseHeader('Content-Length')),
                });
            }
        };
        xhr.send();
    };
    Home.prototype._uploadRequest = function () {
        var _this = this;
        console.log('_uploadRequest');
        var uploadBytes = 32 * 1024 * 1024;
        var xhr = new XMLHttpRequest();
        xhr.open('POST', config.API_HOST + "/upload", true);
        xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded');
        xhr.onerror = function (error) {
            console.log('onerror');
            _this.setState({
                isRunning: false,
                startedDate: Date.now(),
                updatedDate: Date.now(),
                allBytes: 0,
                error: 'network_error',
            });
        };
        xhr.onloadstart = function () {
            console.log('onloadstart');
            _this.setState({
                isRunning: true,
                startedDate: Date.now(),
                updatedDate: Date.now(),
                allBytes: uploadBytes,
                error: null,
            });
        };
        xhr.upload.addEventListener('progress', function (event) {
            console.log('onprogress', event.loaded);
            if (event.lengthComputable) {
                _this.setState({
                    bytesDownloaded: event.loaded,
                    updatedDate: Date.now(),
                    allBytes: event.total,
                });
            }
        });
        xhr.onloadend = function (event) {
            var _a;
            console.log('onloadend');
            if (xhr.readyState !== xhr.DONE) {
                return;
            }
            if (xhr.status > 300) {
                _this.setState({
                    updatedDate: Date.now(),
                    endedDate: Date.now(),
                    isRunning: false,
                    error: ((_a = JSON.parse(xhr.response)) === null || _a === void 0 ? void 0 : _a.error) || 'ui_unknown_error',
                });
                return;
            }
            _this.setState({
                updatedDate: Date.now(),
                endedDate: Date.now(),
                isRunning: false,
                error: null,
            });
        };
        xhr.send(new ArrayBuffer(uploadBytes));
    };
    Home.prototype.render = function () {
        var _this = this;
        var _a = this.state, isRunning = _a.isRunning, startedDate = _a.startedDate, updatedDate = _a.updatedDate, endedDate = _a.endedDate, bytesDownloaded = _a.bytesDownloaded, allBytes = _a.allBytes, error = _a.error;
        return (React.createElement(Container, null,
            React.createElement(Wrapper, null,
                React.createElement(Header, null, "Speedtest"),
                React.createElement(ButtonWrap, null,
                    React.createElement(Button, { disabled: isRunning, onClick: function () {
                            _this._downloadRequest();
                        } }, "Check Download Speed"),
                    React.createElement(Button, { disabled: isRunning, onClick: function () {
                            _this._uploadRequest();
                        } }, "Check Upload Speed")),
                React.createElement(Speed, null, !error
                    ? "Speed - " + (_.round((((bytesDownloaded / 1024 / 1024) * 8) /
                        (updatedDate - startedDate)) *
                        1000, 2) || 0) + " mbps"
                    : error),
                React.createElement(ProgressWrapper, null,
                    React.createElement(Donut, { strokeWidth: 3, size: 256, color: !error ? '#2a6044' : '#ff0000', value: bytesDownloaded / allBytes || 0 }),
                    React.createElement(Progress, null,
                        _.ceil((bytesDownloaded / allBytes) * 100) || 0,
                        "%")))));
    };
    return Home;
}(Component));
export { Home };
var Wrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  margin: 0px auto;\n"], ["\n  margin: 0px auto;\n"])));
var ProgressWrapper = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  width: 256px;\n  height: 256px;\n  position: relative;\n  margin: 0px auto;\n"], ["\n  width: 256px;\n  height: 256px;\n  position: relative;\n  margin: 0px auto;\n"])));
var Speed = styled(Lead)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  text-align: center;\n  padding: 20px;\n"], ["\n  text-align: center;\n  padding: 20px;\n"])));
var Progress = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  width: 256px;\n  height: 256px;\n  display: flex;\n  position: absolute;\n  top: 0px;\n  align-items: center;\n  justify-content: center;\n  font-size: 36px;\n"], ["\n  width: 256px;\n  height: 256px;\n  display: flex;\n  position: absolute;\n  top: 0px;\n  align-items: center;\n  justify-content: center;\n  font-size: 36px;\n"])));
var Button = styled(ButtonOutline)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin: 0px 20px;\n  padding: 20px;\n  width: 25%;\n  color: ", ";\n\n  &:disabled:hover {\n    background-color: white;\n    box-shadow: inset 0 0 0 2px;\n    color: ", ";\n  }\n  &:hover {\n    color: ", ";\n    background-color: ", ";\n  }\n\n  @media (max-width: 667px) {\n    width: 100%;\n    padding: 10px;\n    margin: 5px 0px;\n  }\n"], ["\n  margin: 0px 20px;\n  padding: 20px;\n  width: 25%;\n  color: ", ";\n\n  &:disabled:hover {\n    background-color: white;\n    box-shadow: inset 0 0 0 2px;\n    color: ", ";\n  }\n  &:hover {\n    color: ", ";\n    background-color: ", ";\n  }\n\n  @media (max-width: 667px) {\n    width: 100%;\n    padding: 10px;\n    margin: 5px 0px;\n  }\n"])), function (props) { return props.theme.primary; }, function (props) { return props.theme.primary; }, function (props) { return props.theme.light; }, function (props) { return props.theme.primary; });
var ButtonWrap = styled.div(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  display: flex;\n  justify-content: center;\n  @media (max-width: 667px) {\n    flex-direction: column;\n  }\n"], ["\n  display: flex;\n  justify-content: center;\n  @media (max-width: 667px) {\n    flex-direction: column;\n  }\n"])));
var Header = styled(Heading)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  color: #1c1c1c;\n  font-size: 2em;\n  margin: 10px 0px;\n  text-transform: uppercase;\n  text-align: center;\n"], ["\n  color: #1c1c1c;\n  font-size: 2em;\n  margin: 10px 0px;\n  text-transform: uppercase;\n  text-align: center;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7;
